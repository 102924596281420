import React from 'react';
import { StyleSheet, View } from 'react-native';

const DummyNavButton = () => <View style={styles.dummyNavBtn} />;

const styles = StyleSheet.create({
  dummyNavBtn: {
    height: 24,
    width: 24,
  },
});

export default DummyNavButton;
